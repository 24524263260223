/* HeroSection.css */
.hero-container {
  position: relative;
  height: 100vh;
  background: url("../../public/images/herosection.jpg") center center/cover
    no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent dark overlay */
  z-index: 1;
}

.hero-content {
  position: relative;
  z-index: 2;
  max-width: 800px;
  padding: 20px;
}

.hero-title {
  font-size: 4rem;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 20px;
  animation: slideIn 1.5s ease-out forwards;
}

.hero-subtitle {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 30px;
  opacity: 0.8;
  animation: fadeIn 2s ease-out forwards;
}

.hero-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.btn {
  padding: 12px 28px;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #ff6f61;
  color: white;
}

.btn-primary:hover {
  background-color: #ff3f30;
}

.btn-secondary {
  background-color: transparent;
  border: 2px solid #ffffff;
  color: white;
}

.btn-secondary:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Animation */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }

  .hero-subtitle {
    font-size: 1.2rem;
  }

  .hero-buttons {
    flex-direction: column;
    gap: 15px;
  }
}
