.services-container {
  padding: 80px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.services-title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 60px;
  color: #2c2c54;
  position: relative;
}

.services-title::after {
  content: "";
  display: block;
  width: 80px;
  height: 5px;
  background-color: #6c63ff;
  margin: 1rem auto 0;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 30px; /* Reduced the gap between the cards */
  padding: 0 20px;
}

.service-card {
  background-color: #fff;
  border-radius: 14px; /* Slightly smaller border radius */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  padding: 20px; /* Reduced padding */
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
  max-width: 320px; /* Restrict max width for smaller card size */
}

.service-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
}

.service-image-container {
  overflow: hidden;
  border-radius: 12px;
  margin-bottom: 15px; /* Reduced margin below image */
}

.service-image {
  width: 100%;
  height: 210px; /* Smaller height for the image */
  object-fit: cover; /* Ensure image fits without distortion */
  border-radius: 12px;
  transition: transform 0.4s ease, opacity 0.4s ease;
}

.service-card:hover .service-image {
  transform: scale(1.05); /* Slight zoom on hover */
  opacity: 0.9;
}

.service-title {
  font-size: 1.5rem; /* Reduced font size */
  font-weight: 600;
  margin: 15px 0;
  color: #2c2c54;
}

.service-description {
  font-size: 1rem; /* Slightly smaller text size */
  color: #666;
  line-height: 1.6;
}

.service-card:hover .service-title,
.service-card:hover .service-description {
  color: black;
}

.services-cta {
  margin-top: 60px;
}

.btn {
  padding: 14px 30px;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  border-radius: 25px;
  background-color: #6c63ff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.btn:hover {
  background-color: #5a54db;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}
