.washing-machine-container {
    padding: 20px;
    max-width: 800px;
    margin: 79px auto;
  }
  
  .machine-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .machine-item {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .machine-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .machine-description {
    flex: 1;
  }
  
  .machine-description p {
    margin: 5px 0;
    line-height: 1.5;
  }
  
  /* Responsive Styling */
  @media (max-width: 600px) {
    .machine-item {
      flex-direction: column;
      align-items: center;
    }
  
    .machine-image {
      width: 100%;
      height: auto;
    }
  }
  