/* src/styles/Navbar.css */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent white */
  backdrop-filter: blur(10px); /* Glass effect */
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: fixed; /* Fixed positioning */
  top: 0; /* Stay at the top */
  width: 100%; /* Full width */
  z-index: 100; /* Stay on top of other content */
  border-bottom: 0.1px solid rgba(255, 255, 255, 0.3); /* Light border for depth */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.navbar-black {
  background-color: rgba(49, 48, 48, 0.8); /* Dark background when scrolled */
}

.navbar:hover {
  background-color: rgba(37, 36, 36, 0.2); /* Darker on hover */
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  background-color: rgba(37, 36, 36, 0.2);
}

.navbar-logo {
  color: #fff;
  font-size: 2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar-logo:hover {
  color: #f0a500; /* Change color on hover */
}

.menu-icon {
  display: none; /* Hidden in desktop view */
}

.nav-menu {
  display: flex;
  list-style: none;
  margin-right: -22px;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  position: relative; /* Positioning for hover effect */
  overflow: hidden; /* Prevent overflow of pseudo-element */
}

.nav-links::after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: #f0a500; /* Underline color */
  transition: width 0.3s ease;
  position: absolute;
  bottom: -5px; /* Position the underline */
  left: 50%;
  transform: translateX(-50%); /* Center the underline */
}

.nav-links:hover {
  color: #f0a500; /* Change text color on hover */
}

.nav-links:hover::after {
  width: 100%; /* Underline expands to full width on hover */
}

/* Mobile View */
@media screen and (max-width: 960px) {
  .navbar-container {
    padding: 0 30px;
  }

  .menu-icon {
    display: block; /* Show in mobile view */
    color: #fff;
    font-size: 1.8rem;
    cursor: pointer;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    background-color: rgba(
      51,
      51,
      51,
      0.9
    ); /* Dark semi-transparent for mobile */
  }

  .nav-menu.active {
    left: 0; /* Show menu when active */
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-item {
    height: 60px; /* Adjust height for mobile */
  }

  .nav-links {
    display: block;
    text-align: center;
    padding: 2rem;
    width: 100%;
  }

  .nav-links:hover {
    background-color: #f0a500; /* Change to a bright color */
    color: #333; /* Text color on hover */
    transition: all 0.3s ease;
  }
}
