/* ProjectsSection.css */
.projects-container {
  padding: 80px 20px;
  background-color: #fafafa;
  text-align: center;
}

.projects-title {
  font-size: 2.8rem;
  font-weight: 700;
  margin-bottom: 50px;
  color: #2c2c54;
  position: relative;
}

.projects-title::after {
  content: "";
  display: block;
  width: 60px;
  height: 4px;
  background-color: #6c63ff;
  margin: 0.5rem auto 0;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 35px;
  padding: 0 20px;
}

.project-card {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  padding: 40px 30px; /* Padding for overall card */
  transition: transform 0.4s ease, box-shadow 0.4s ease,
    background-color 0.4s ease, color 0.4s ease;
  position: relative;
  overflow: hidden;
  border: 1px solid #ebebeb;
  cursor: pointer;
  display: flex; /* Added to control layout */
  flex-direction: column; /* Stack elements vertically */
  justify-content: space-between; /* Evenly distribute space */
}

.project-card:hover {
  background-color: #2c2c54;
  color: #fff;
  transform: translateY(-12px);
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.15);
}

.project-image-container {
  overflow: hidden;
  border-radius: 12px;
  margin-bottom: 20px; /* Space below image */
}

.project-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 12px;
  transition: transform 0.4s ease;
}

.project-card:hover .project-image {
  transform: scale(1.1); /* Zoom effect on hover */
}

.project-title {
  font-size: 1.7rem;
  font-weight: 600;
  margin: 20px 0;
  color: #2c2c54;
  transition: color 0.4s ease;
}

.project-description {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px; /* Margin to separate from button */
  transition: color 0.4s ease;
}

.project-card:hover .project-title,
.project-card:hover .project-description {
  color: #fff; /* Change title and description to white on hover */
}

.btn {
  text-decoration: none;
  padding: 14px 32px;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  border-radius: 25px;
  background-color: #6c63ff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-top: auto; /* Push button to the bottom */
}

.btn:hover {
  background-color: #5a54db;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}
