/* Contact.css */
.contact-container {
  padding: 80px 20px;
  background: linear-gradient(135deg, #6c63ff 0%, #3f3f5f 100%);
  color: white;
  text-align: center;
}

.contact-title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.contact-description {
  font-size: 1.25rem;
  line-height: 1.8;
  max-width: 600px;
  margin: 0 auto 40px;
  opacity: 0.9;
}

.contact-info {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  gap: 20px; /* Use gap for spacing between items */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.contact-info-item {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 20px;
  width: 285px; /* Consistent width for each item */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.contact-info-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.contact-icon {
  font-size: 2rem;
  color: white;
  margin-bottom: 10px;
}

.contact-form-container {
  max-width: 600px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-size: 1rem;
  color: #2c2c54;
  display: block;
  margin-bottom: 5px;
}

input,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  color: #333;
  transition: border-color 0.3s ease;
}

input:focus,
textarea:focus {
  border-color: #6c63ff;
  outline: none;
}

textarea {
  resize: none;
}

.btn {
  padding: 14px 32px;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  border-radius: 25px;
  background-color: #6c63ff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.btn:hover {
  background-color: #9997b8;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
  .contact-title {
    font-size: 2.5rem;
  }

  .contact-info {
    flex-direction: column;
    align-items: center;
  }

  .contact-info-item {
    width: 100%;
    margin: 10px 0;
  }

  .contact-form-container {
    padding: 30px;
  }
}

@media (max-width: 480px) {
  .contact-title {
    font-size: 2rem;
  }

  .contact-description {
    font-size: 1rem;
  }

  .btn {
    padding: 12px 24px;
    font-size: 1rem;
  }
}
