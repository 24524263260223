/* About.css */
.about-container {
  padding: 80px 20px; /* Ample padding for breathing space */
  background: linear-gradient(
    to right,
    #f9f9f9,
    #eaeaea
  ); /* Soft gradient background */
  text-align: center; /* Center text alignment */
}

.about-content {
  max-width: 900px; /* Restrict width for better readability */
  margin: 0 auto; /* Center the content */
}

.about-title {
  font-size: 3rem; /* Larger font size for the title */
  font-weight: 700; /* Bold text for emphasis */
  color: #2c2c54; /* Dark color for title */
  margin-bottom: 20px; /* Space below title */
  position: relative; /* Position for pseudo elements */
}

.about-title::after {
  content: "";
  display: block;
  width: 80px; /* Width of the underline */
  height: 4px; /* Height of the underline */
  background-color: #6c63ff; /* Color of the underline */
  margin: 0.5rem auto 0; /* Centering the underline */
}

.highlight {
  color: #6c63ff; /* Highlight color for company name */
  font-weight: bold; /* Emphasize the company name */
}

.about-description {
  font-size: 1.25rem; /* Readable font size */
  color: #555; /* Dark grey for description text */
  line-height: 1.8; /* Improved line height for readability */
  max-width: 800px; /* Max width to maintain readability */
  margin: 0 auto 40px; /* Centering and spacing */
  opacity: 0.85; /* Slight transparency for elegance */
}

.about-image-container {
  margin-bottom: 40px; /* Space below image */
}

.about-image {
  width: 100%; /* Full width responsive image */
  height: auto; /* Maintain aspect ratio */
  border-radius: 12px; /* Rounded corners for a softer look */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15); /* Soft shadow for depth */
  transition: transform 0.4s ease; /* Transition effect */
}

.about-image:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

.about-subtitle {
  font-size: 2.5rem; /* Subtitle size */
  font-weight: 600; /* Semi-bold */
  color: #2c2c54; /* Dark color */
  margin-top: 40px; /* Space above subtitle */
}

.about-values {
  list-style-type: none; /* Remove default list styles */
  padding: 0; /* Remove padding */
  margin: 20px 0; /* Margin above and below */
  font-size: 1.2rem; /* Font size for values */
  color: #555; /* Text color for values */
  display: flex; /* Flex layout for alignment */
  flex-direction: column; /* Stack values vertically */
  gap: 15px; /* Space between items */
}

.about-values li {
  padding: 10px 20px; /* Padding for each item */
  border-radius: 8px; /* Rounded corners */
  background-color: #f0f0f0; /* Light background for each item */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Transition effects */
}

.about-values li:hover {
  background-color: #6c63ff; /* Change background on hover */
  color: white; /* Change text color to white */
  transform: translateY(-3px); /* Subtle upward movement on hover */
}
